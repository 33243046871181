import styled from 'styled-components'

const TeamSectionStyle = styled.div`
	--tsTitleMarginBottom: var(--sp12x);
	--tsMemberImageProportion: 124.9%;
	--tsMemberImageBorderRadius: var(--sp4x);
	--tsMemberNameFontSize: var(--h6);
	--tsMemberNameMarginTop: var(--sp21x);
	--tsMemberPositionFontSize: var(--p2);
	--tsMemberPositionFontWeight: 600;
	--tsMemberPositionMarginTop: var(--sp3x);
	--tsMemberDescFontSize: var(--p3);
	--tsMemberDescMarginTop: var(--sp7x);
	--tsMemberLinkMarginTop: var(--sp5x);
	--tsMemberMarginTop: var(--sp12x);
	--tsMemberContColPadding: var(--sp26x);
	--juryStarIconMarginTopBottom: var(--sp5x) 0 var(--sp1-5x) 0;

	.ts-board-member-cont {
		margin-top: calc(var(--tsMemberMarginTop) * -1);
	}

	&.ts-board-member-cont {
		> .row {
			--colPadding: var(--tsMemberContColPadding);
		}
	}

	.ts-team-member-cont {
		margin-top: calc(var(--tsMemberMarginTop) * -1);

		.ts-team-member-col {
			margin-top: var(--tsMemberMarginTop);
		}
	}

	.ts-board-member-col {
		--tsMemberImageProportion: 111.13%;
		--tsMemberNameFontSize: var(--p1);
		--tsMemberNameMarginTop: var(--sp4x);
		--tsMemberPositionFontSize: var(--p2);
		--tsMemberPositionFontWeight: 500;
		--tsMemberPositionMarginTop: var(--sp3x);
		--tsMemberLinkMarginTop: var(--sp4x);
		margin-top: var(--tsMemberMarginTop);

		&.ts-small {
			.ts-member-position {
				margin-bottom: auto;
			}
		}

		.ts-member > .row {
			flex-direction: column;
		}
	}

	.ts-title {
		margin-bottom: var(--tsTitleMarginBottom);
	}

	.ts-member {
		height: 100%;

		> .row {
			height: 100%;
		}

		.ts-member-image {
			.image-cont {
				--proportion: var(--tsMemberImageProportion);

				border-radius: var(--tsMemberImageBorderRadius);
				overflow: hidden;
			}
		}

		.ts-member-info {
			display: flex;
			flex-direction: column;
			flex: 1;
			height: 100%;

			&.jury-member-info {
				.ts-member-desc {
					margin-top: unset;
				}

				.jury-star-icon {
					margin: var(--juryStarIconMarginTopBottom);
				}
			}

			.ts-member-name {
				margin-top: var(--tsMemberNameMarginTop);
				font-size: var(--tsMemberNameFontSize);
			}

			.ts-member-position {
				margin-top: var(--tsMemberPositionMarginTop);
				font-size: var(--tsMemberPositionFontSize);
				font-family: var(--plusJakartaSans);
				font-weight: var(--tsMemberPositionFontWeight);
			}

			.ts-member-desc {
				margin-top: var(--tsMemberDescMarginTop);
				font-size: var(--tsMemberDescFontSize);
			}

			.ts-member-link {
				margin-top: var(--tsMemberLinkMarginTop);
				font-size: var(--tsMemberDescFontSize);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--tsTitleMarginBottom: var(--sp9x);
		--tsMemberImageBorderRadius: var(--sp4x);
		--tsMemberNameMarginTop: var(--sp16x);
		--tsMemberPositionMarginTop: var(--sp2x);
		--tsMemberDescMarginTop: var(--sp6x);
		--tsMemberLinkMarginTop: var(--sp3x);
		--tsMemberMarginTop: var(--sp9x);
		--tsMemberContColPadding: var(--sp20x);
		--juryStarIconMarginTopBottom: var(--sp4x) 0 var(--sp1-5x) 0;

		.ts-board-member-col {
			--tsMemberNameMarginTop: var(--sp3x);
			--tsMemberPositionMarginTop: var(--sp2x);
			--tsMemberLinkMarginTop: var(--sp3x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--tsTitleMarginBottom: var(--sp7x);
		--tsMemberImageBorderRadius: var(--sp2-5x);
		--tsMemberNameMarginTop: var(--sp10x);
		--tsMemberPositionMarginTop: var(--sp2x);
		--tsMemberDescMarginTop: var(--sp5x);
		--tsMemberLinkMarginTop: var(--sp3x);
		--tsMemberMarginTop: var(--sp8x);
		--tsMemberContColPadding: var(--sp15x);
		--juryStarIconMarginTopBottom: var(--sp3x) 0 var(--sp1x) 0;

		.ts-board-member-col {
			--tsMemberNameMarginTop: var(--sp3x);
			--tsMemberPositionMarginTop: var(--sp2x);
			--tsMemberLinkMarginTop: var(--sp3x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--tsTitleMarginBottom: var(--sp7x);
		--tsMemberImageBorderRadius: var(--sp2-5x);
		--tsMemberNameMarginTop: var(--sp9x);
		--tsMemberPositionMarginTop: var(--sp2x);
		--tsMemberDescMarginTop: var(--sp5x);
		--tsMemberLinkMarginTop: var(--sp3x);
		--tsMemberMarginTop: var(--sp8x);
		--tsMemberContColPadding: var(--sp13x);
		--juryStarIconMarginTopBottom: var(--sp3x) 0 var(--sp1x) 0;

		.ts-board-member-col {
			--tsMemberNameMarginTop: var(--sp3x);
			--tsMemberPositionMarginTop: var(--sp2x);
			--tsMemberLinkMarginTop: var(--sp3x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--tsTitleMarginBottom: var(--sp6x);
		--tsMemberImageBorderRadius: var(--sp2-5x);
		--tsMemberNameMarginTop: var(--sp9x);
		--tsMemberPositionMarginTop: var(--sp2x);
		--tsMemberDescMarginTop: var(--sp4x);
		--tsMemberLinkMarginTop: var(--sp3x);
		--tsMemberMarginTop: var(--sp7x);
		--tsMemberContColPadding: var(--sp12x);
		--juryStarIconMarginTopBottom: var(--sp3x) 0 var(--sp1x) 0;

		.ts-board-member-col {
			--tsMemberNameMarginTop: var(--sp3x);
			--tsMemberPositionMarginTop: var(--sp2x);
			--tsMemberLinkMarginTop: var(--sp3x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--tsTitleMarginBottom: var(--sp5x);
		--tsMemberImageBorderRadius: var(--sp2x);
		--tsMemberNameMarginTop: var(--sp6x);
		--tsMemberPositionMarginTop: var(--sp2x);
		--tsMemberDescMarginTop: var(--sp3x);
		--tsMemberLinkMarginTop: var(--sp3x);
		--tsMemberMarginTop: var(--sp6x);
		--tsMemberContColPadding: var(--sp11x);
		--juryStarIconMarginTopBottom: var(--sp3x) 0 var(--sp1x) 0;

		.ts-board-member-col {
			--tsMemberNameMarginTop: var(--sp3x);
			--tsMemberPositionMarginTop: var(--sp2x);
			--tsMemberLinkMarginTop: var(--sp3x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--tsTitleMarginBottom: var(--sp5x);
		--tsMemberImageBorderRadius: var(--sp2x);
		--tsMemberNameMarginTop: var(--sp3x);
		--tsMemberPositionMarginTop: var(--sp2x);
		--tsMemberDescMarginTop: var(--sp3x);
		--tsMemberLinkMarginTop: var(--sp3x);
		--tsMemberMarginTop: var(--sp6x);
		--tsMemberContColPadding: var(--sp9x);
		--juryStarIconMarginTopBottom: var(--sp3x) 0 var(--sp1x) 0;

		.ts-board-member-col {
			--tsMemberNameMarginTop: var(--sp3x);
			--tsMemberPositionMarginTop: var(--sp2x);
			--tsMemberLinkMarginTop: var(--sp3x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--tsTitleMarginBottom: var(--sp5x);
		--tsMemberImageBorderRadius: var(--sp1-5x);
		--tsMemberNameMarginTop: var(--sp3x);
		--tsMemberPositionMarginTop: var(--sp1-5x);
		--tsMemberDescMarginTop: var(--sp3x);
		--tsMemberLinkMarginTop: var(--sp3x);
		--tsMemberMarginTop: var(--sp5x);
		--tsMemberContColPadding: var(--sp2x);
		--juryStarIconMarginTopBottom: var(--sp2-5x) 0 var(--sp1x) 0;

		.ts-board-member-col {
			--tsMemberNameMarginTop: var(--sp2-5x);
			--tsMemberPositionMarginTop: var(--sp1-5x);
			--tsMemberLinkMarginTop: var(--sp2-5x);
		}
	}
`

export default TeamSectionStyle
