import { useMemo, useState } from 'react'

//* Components
import Text from 'components/common/Text'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Grid } from 'swiper'
import Container from 'components/common/Container'
import CustomLink from 'components/common/CustomLink'
import Image from 'components/common/Image'
import Icon from 'components/common/Icon'

//* Style
import OurPartnersStyle from './style'
import 'swiper/css/navigation'
import 'swiper/css/grid'

const OurPartners = ({ partners, title }) => {

    //! store slides
    const storeSlide = useMemo(() => {
        return partners.map((partner, index) => {
            return partner.url ? (
                <SwiperSlide key={index}>
                    <CustomLink
                        className={'slide'}
                        url={partner.url}
                        target={'_blank'}
                    >
                        <Image
                            className={'partner-image'}
                            src={partner.logo.src}
                            alt={partner.logo.alt}
                        />
                    </CustomLink>
                </SwiperSlide>
            ) : (
                <SwiperSlide key={index}>
                    <div className={'slide'}>
                        <Image
                            className={'partner-image'}
                            src={partner.logo.src}
                            alt={partner.logo.alt}
                        />
                    </div>
                </SwiperSlide>
            )
        })
    }, [partners])

    return (
        <OurPartnersStyle >
            <Container section>
                <div className='title-and-arrow-wrap'>
                    <Text
                        className={'h3 birodo-green font-reckless-neue-semibold partners-title'}
                        text={title || 'ourPartners'}
                    />
                    <div className='arrows-wrap'>
                        <Icon
                            name={'arrow'}
                            className='arrow-prev slide-control-arrow'
                        />
                        <Icon
                            name={'arrow'}
                            className='arrow-next slide-control-arrow'
                        />
                    </div>
                </div>
                <Swiper
                    modules={[Grid, Navigation]}
                    navigation={{
                        nextEl: '.arrow-next',
                        prevEl: '.arrow-prev',
                    }}
                    breakpoints={{
                        1024: {
                            slidesPerView: 5,
                        },
                        768: {
                            slidesPerView: 4,
                        },
                        1: {
                            slidesPerView: 2,
                            grid: {
                                rows: 2,
                            },
                        },
                    }}
                    className='mySwiper'
                    slidesPerView={5}
                >
                    {storeSlide}
                </Swiper>
            </Container>
        </OurPartnersStyle>
    )
}

export default OurPartners
