import { memo as Memo, useMemo } from 'react'

//* Styles
import TeamSectionStyle from './style'

//* Components
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Image from 'components/common/Image'
import Button from 'components/common/Button'
import Icon from 'components/common/Icon'

const TeamSection = Memo(({ title, team, advisoryBoard = false, textColor, full, section, first, className, isJury }) => {
    //! Team Members
    const teamMembers = useMemo(() => {
        const colClasses = advisoryBoard ? 'col-3 col-t-4 col-m-6 ts-board-member-col ts-small' : 'col-6 col-m-12 ts-team-member-col'
        const infoClasses = advisoryBoard ? 'col-12' : 'col-6 col-ts-12'

        return (
            <Container
                className={`ts-members-cont ${advisoryBoard ? 'ts-board-member-cont' : 'ts-team-member-cont'}`}
                row
                full
            >
                {team.map((t, k) => {
                    return (
                        <div
                            key={`${t.full_name}-${k}`}
                            className={`${colClasses} birodo-green`}
                        >
                            <Container
                                full
                                row
                                className='ts-member'
                            >
                                <div className={`${infoClasses} ts-member-image`}>
                                    <Image
                                        src={t.image.src}
                                        alt={t.image.alt}
                                    />
                                </div>

                                <div className={`${infoClasses} ${isJury ? 'jury-member-info' : ''} ts-member-info`}>
                                    <Text
                                        tag='h3'
                                        text={t.full_name || t.title}
                                        className={`ts-member-name font-jakarta-sans-semibold ${textColor}`}
                                    />
                                    <Text
                                        tag='h4'
                                        text={t.position || t.subtitle}
                                        className={`ts-member-position ${textColor}`}
                                    />
                                    {t.description ||
                                        (t.text && (
                                            <>
                                                {isJury && (
                                                    <Icon
                                                        className={'p p1 jury-star-icon'}
                                                        name={'star'}
                                                    />
                                                )}
                                                <Text
                                                    text={t.description || t.text}
                                                    className={`ts-member-desc font-jakarta-sans-medium ${textColor}`}
                                                />
                                            </>
                                        ))}

                                    {t.url && (
                                        <Button
                                            url={t.url}
                                            target='_blank'
                                            customLinkProps={{
                                                rel: 'noopener noreferrer',
                                            }}
                                            btnType='icon'
                                            iconName='linkedin'
                                            className='ts-member-link'
                                            iconColor={textColor && 'birodoGreen'}
                                            backgroundColor={textColor}
                                        />
                                    )}
                                </div>
                            </Container>
                        </div>
                    )
                })}
            </Container>
        )
    }, [team, advisoryBoard])

    return (
        <TeamSectionStyle className={className || ''}>
            <Container
                section={section}
                full={full}
                first={first}
            >
                <Text
                    tag='h2'
                    text={title}
                    className={`ts-title birodo-green h3 font-reckless-neue-semibold ${textColor}`}
                />

                {teamMembers}
            </Container>
        </TeamSectionStyle>
    )
})

TeamSection.defaultProps = {
    isAboutUs: false,
}

export default TeamSection
