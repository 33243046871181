import styled from 'styled-components'

const OurPartnersStyle = styled.div`
	--partnersTitleMarginBottom: var(--sp12x);
	--spaceBetweenSlides: calc(var(--sp27x) / 2);
	--slideControlArrowSize: var(--sp10x);
	--slideControlArrowIconSize: var(--sp5x);
	--slideArrowNextMarginLeft: var(--sp5x);
	--partnerImagePaddingTop: 61.8%;

    overflow: hidden;

	.title-and-arrow-wrap {
		margin-bottom: var(--partnersTitleMarginBottom);
		display: flex;
		align-items: center;
		justify-content: space-between;

		.arrows-wrap {
			display: flex;

			.slide-control-arrow {
				display: flex;
				justify-content: center;
				align-items: center;
				width: var(--slideControlArrowSize);
				height: var(--slideControlArrowSize);
				font-size: var(--slideControlArrowIconSize);
				background-color: var(--birodoGreen);
				border-radius: var(--sp1x);
				cursor: pointer;

				&.swiper-button-lock {
					display: none;
				}

				&::before {
					color: var(--white);
				}

				&.swiper-button-disabled {
					pointer-events: none;
					background-color: var(--durianSmell);

					&::before {
						color: var(--birodoGreen);
					}
				}

				&.arrow-next {
					margin-left: var(--slideArrowNextMarginLeft);
					transform: rotate(-90deg);
				}

				&.arrow-prev {
					transform: rotate(90deg);
				}
			}
		}
	}

	.swiper {
		margin: 0 calc(var(--spaceBetweenSlides) * -1);

		.slide {
			display: block;
			padding: 0 var(--spaceBetweenSlides);

			.partner-image {
				--proportion: var(--partnerImagePaddingTop);

				img {
					object-fit: contain;
				}
			}
		}
	}

	.swiper-grid-column {
		.swiper-wrapper {
			flex-direction: unset;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--partnersTitleMarginBottom: var(--sp9x);
		--spaceBetweenSlides: calc(var(--sp20x) / 2);

		--slideControlArrowSize: var(--sp8x);
		--slideControlArrowIconSize: var(--sp4x);
		--slideArrowNextMarginLeft: var(--sp4x);

		--partnerImagePaddingTop: 60.6%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--partnersTitleMarginBottom: var(--sp7x);
		--spaceBetweenSlides: calc(var(--sp17x) / 2);

		--slideControlArrowSize: var(--sp7x);
		--slideControlArrowIconSize: var(--sp3x);
		--slideArrowNextMarginLeft: var(--sp3x);

		--partnerImagePaddingTop: 60.6%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--partnersTitleMarginBottom: var(--sp7x);
		--spaceBetweenSlides: calc(var(--sp17x) / 2);

		--slideControlArrowSize: var(--sp7x);
		--slideControlArrowIconSize: var(--sp3x);
		--slideArrowNextMarginLeft: var(--sp3x);

		--partnerImagePaddingTop: 61.1%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--partnersTitleMarginBottom: var(--sp6x);
		--spaceBetweenSlides: calc(var(--sp15x) / 2);

		--slideControlArrowSize: var(--sp6x);
		--slideControlArrowIconSize: var(--sp3x);
		--slideArrowNextMarginLeft: var(--sp3x);

		--partnerImagePaddingTop: 64%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--partnersTitleMarginBottom: var(--sp5x);
		--spaceBetweenSlides: calc(var(--sp13x) / 2);

		--slideControlArrowSize: var(--sp5x);
		--slideControlArrowIconSize: var(--sp2x);
		--slideArrowNextMarginLeft: var(--sp2x);

		--partnerImagePaddingTop: 58.8%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--partnersTitleMarginBottom: var(--sp5x);
		--spaceBetweenSlides: calc(var(--sp8x) / 2);

		--slideControlArrowSize: var(--sp5x);
		--slideControlArrowIconSize: var(--sp2x);
		--slideArrowNextMarginLeft: var(--sp2x);

		--partnerImagePaddingTop: 61%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--partnersTitleMarginBottom: var(--sp4x);
		--spaceBetweenSlides: calc(var(--sp8x) / 2);

		--slideControlArrowSize: var(--sp4x);
		--slideControlArrowIconSize: var(--sp2x);
		--slideArrowNextMarginLeft: var(--sp2x);

		--partnerImagePaddingTop: 51%;

		.slide {
			margin-top: var(--sp4x);
		}

		.title-and-arrow-wrap {
			margin-bottom: unset;
		}
	}
`

export default OurPartnersStyle
